import React from "react";
import styled from "styled-components";
import { Box, Title, MainTitle } from "../../components/Core";
import { device } from "../../utils";
import { Col, Container, Row } from "react-bootstrap";
import AdditionalSupportInformation from "../../sections/support/additionalSupportInformation";
import { graphql, Link, useStaticQuery } from "gatsby";
import { getProperty } from "../../utils/helperFn";
import Seo from "../../components/Seo/Seo";
import TextFit from "./../../components/Rebrand/TextFit/TextFit";
import { navigate } from "gatsby";

const LegalPage = (props) => {

    const data = useStaticQuery(graphql`
     query getAllLegalCategories {
      allStrapiLegalCategories {
        nodes {
          name
          slug
        }
      }
    }`)

    const legalCategories = getProperty(data, 'allStrapiLegalCategories');

    const renderLegalCategory = () => (
        legalCategories.nodes.map(node => (
            <Col sm={6} md={4} lg={2}>
                <div className="legal-category" onClick={() => navigate(node.slug)}>
                    <h4>{node.name}</h4>
                    <Link to={node.slug}>
                        Read more
                    </Link>
                </div>
            </Col>
        ))
    )

    return (
        <div>
            <Seo page="legal" />
            <Container className="mb-50">
                <Row className="mb-50">
                    <Col sm={7} className="light-bg mb-50">
                        <h1>
                            <TextFit text="THE LEGAL BIT" />
                        </h1>
                    </Col>
                    <Col sm={12}>
                        <Row>
                            {renderLegalCategory()}
                        </Row>
                    </Col>
                </Row>
                {/* <AdditionalSupportInformation /> */}
            </Container>
        </div>
    )
}

export default LegalPage